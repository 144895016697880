const CONTENTDATA = [{
    name: 'Измени жизнь, оставаясь собой',
    author: 'Татьяна Мужицкая, Ирина Белашева',
    text: 'Хватит пытаться стать другим человеком и&nbsp;подстраиваться под чужие стандарты.<span> Перестаньте бороться с&nbsp;собой&nbsp;&mdash; и&nbsp;все получится!</span>',
    img: './img/books/change-live.png'
  },
  {
    name: 'Разговор с незнакомцем',
    author: 'Малкольм Гладуэлл',
    text: '<span> Выигрыш огромен</span>, а&nbsp;издержки на&nbsp;его фоне ничтожны.',
    img: './img/books/stranger.png'
  },
  {
    name: 'Разговор с незнакомцем',
    author: 'Малкольм Гладуэлл',
    text: 'Чем упорнее мы&nbsp;заставляем незнакомцев раскрыться,<span>  тем непостижимее они становятся.</span>',
    img: './img/books/stranger.png'
  },
  {
    name: 'Победи прокрастинацию',
    author: 'Петр Людвиг',
    text: 'Долговременного состояния удовлетворенности мы&nbsp;сможем достичь,<span>  научившись проживать каждый день наполненно и&nbsp;осмысленно.</span>',
    img: './img/books/win.png'
  },
  {
    name: 'Прощайте, вещи!',
    author: 'Фумио Сасаки',
    text: 'Счастье в&nbsp;том,<span> чтобы владеть малым.</span>',
    img: './img/books/farewell.png'
  },
  {
    name: 'Прощайте, вещи!',
    author: 'Фумио Сасаки',
    text: 'Выбросить что-либо вовсе<span> не&nbsp;означает отказаться от&nbsp;воспоминаний.</span>',
    img: './img/books/farewell.png'
  },
  {
    name: 'Тирания тревоги',
    author: 'Анна Погребняк',
    text: 'Что такое принятие? Это значит<span> сказать &laquo;да&raquo; реальности, какой&nbsp;бы она ни&nbsp;была.</span>',
    img: './img/books/tyranny.png'
  },
  {
    name: 'Тирания тревоги',
    author: 'Анна Погребняк',
    text: 'Вы&nbsp;не&nbsp;можете управлять мыслями,<span> но&nbsp;вы&nbsp;можете управлять вниманием.</span>',
    img: './img/books/tyranny.png'
  },
  {
    name: 'Тирания тревоги',
    author: 'Анна Погребняк',
    text: 'Ваша задача&nbsp;&mdash; изменить привычный образ мышления,<span> заменить негативные мысли рациональными и&nbsp;реалистичными.</span>',
    img: './img/books/tyranny.png'
  },
  {
    name: 'Настольная книга лидера',
    author: 'Рон Ашкеназ, Брук Мэнвилл',
    text: 'Настоящие лидеры процветают в&nbsp;условиях неизвестности, творчества и&nbsp;открытий,<span> инициируя кардинальные перемены.</span>',
    img: './img/books/rule.png'
  },
  {
    name: 'Настольная книга лидера',
    author: 'Рон Ашкеназ, Брук Мэнвилл',
    text: 'Прорыв на&nbsp;новый уровень лидерства потребует от&nbsp;вас начать мыслить по-новому и,<span>  возможно, даже изменить восприятие самого себя.</span>',
    img: './img/books/rule.png'
  },
  {
    name: 'Настольная книга лидера',
    author: 'Рон Ашкеназ, Брук Мэнвилл',
    text: 'Чтобы достичь успеха и&nbsp;найти верный путь в&nbsp;эти сложные времена,<span>  нужно отключиться от&nbsp;внешних шумов и&nbsp;сосредоточиться на&nbsp;основах.</span>',
    img: './img/books/rule.png'
  },
  {
    name: 'Дистанция счастья',
    author: 'Илсе Санд',
    text: 'Достичь умиротворения помогает решимость не&nbsp;отступать от&nbsp;собственных принципов,<span>  даже вопреки желаниям и&nbsp;требованиям окружения.</span>',
    img: './img/books/happiness.png'
  },
  {
    name: 'Дистанция счастья',
    author: 'Илсе Санд',
    text: 'Приоритет лучше отдавать собственным ценностям:<span>  правде, любви, заботе о&nbsp;нуждающихся, экологии, верности, свободе и&nbsp;т.д.</span>',
    img: './img/books/happiness.png'
  },
  {
    name: 'Деньги делают деньги',
    author: 'Дмитрий Лебедев',
    text: 'Если ничего не&nbsp;менять,<span>  то&nbsp;ничего и&nbsp;не&nbsp;изменится.</span>',
    img: './img/books/money.png'
  },
  {
    name: 'Деньги делают деньги',
    author: 'Дмитрий Лебедев',
    text: 'Самое главное&nbsp;&mdash; развиваться, трудиться, повышать квалификацию,<span>  и&nbsp;деньги придут.</span>',
    img: './img/books/money.png'
  },
  {
    name: 'Любить. Считать.',
    author: 'Светлана Шишкина',
    text: 'Делегировать по&nbsp;максимуму. Все, что возможно. Договориться на&nbsp;берегу. Если не&nbsp;договорились или договорились плохо&nbsp;&mdash; передоговориться.<span>  Не&nbsp;пытаться быть удобной.</span>',
    img: './img/books/love.png'
  },
  {
    name: 'Любить. Считать.',
    author: 'Светлана Шишкина',
    text: 'Планируйте расходы<span>  на&nbsp;год вперед.</span>',
    img: './img/books/love.png'
  },
  {
    name: 'Любить. Считать.',
    author: 'Светлана Шишкина',
    text: 'Поддерживайте боевой дух мыслями<span>  о&nbsp;долгосрочных целях, которые достигаете.</span>',
    img: './img/books/love.png'
  },
  {
    name: 'Щепотка магии',
    author: 'Мишель Харрисон',
    text: 'Тот, кто пробует,&nbsp;&mdash;<span>  побеждает.</span>',
    img: './img/books/magic.png'
  },
  {
    name: 'Энергия — новая валюта',
    author: 'Леонид Кроль',
    text: 'Ответ парадоксальный: вложить немного собственной энергии<span>  в&nbsp;фантазии о&nbsp;людях и&nbsp;в&nbsp;реальные взаимодействия с&nbsp;ними.</span>',
    img: './img/books/energy.png'
  },
  {
    name: 'Критическое мышление',
    author: 'Никита Непряхин, Тарас Пащенко',
    text: 'Многим из&nbsp;нас свойственно принимать решение,<span>  прямо противоположное тому, что советуют нам окружающие.</span>',
    img: './img/books/thinking.png'
  },
  {
    name: 'Миллион подписчиков',
    author: 'Брендан Кейн',
    text: 'Не&nbsp;нужно пытаться заново изобрести велосипед.<span>  Оглянитесь вокруг и&nbsp;позаимствуйте у&nbsp;других идеи, которые работают.</span>',
    img: './img/books/followers.png'
  },
  {
    name: 'Чувак и мастер дзен',
    author: 'Джефф Бриджес, Берни Глассман',
    text: 'Думаю, каждый, кого ты&nbsp;встречаешь, становится твоим гуру, учит тебя чему-то. Но, как ты&nbsp;говоришь, если хочешь добраться до&nbsp;одного берега, а&nbsp;потом до&nbsp;следующего,<span>  нужно менять лодки, весла и&nbsp;людей, с&nbsp;которыми тусуешься.</span>',
    img: './img/books/master.png'
  },
  {
    name: 'Чувак и мастер дзен',
    author: 'Джефф Бриджес, Берни Глассман',
    text: 'Вера в&nbsp;себя&nbsp;&mdash; вот что важно. Вера в&nbsp;то, что ты&nbsp;будешь действовать<span>  самым подходящим образом в&nbsp;конкретной ситуации, в&nbsp;конкретный момент.</span>',
    img: './img/books/master.png'
  },
  {
    name: 'Чувак и мастер дзен',
    author: 'Джефф Бриджес, Берни Глассман',
    text: 'В&nbsp;жизни очень важно практиковать. Это один из&nbsp;способов<span>  достижения гармонии со&nbsp;всем вокруг.</span>',
    img: './img/books/master.png'
  },
  {
    name: 'Чувак и мастер дзен',
    author: 'Джефф Бриджес, Берни Глассман',
    text: 'Для начала ты&nbsp;должен оказаться в&nbsp;пространстве не-знания, отпустить привязанность к&nbsp;тому, кем себя считаешь,<span>  и&nbsp;затем свидетельствовать жизнь вокруг тебя, то&nbsp;есть стать&nbsp;ей.</span>',
    img: './img/books/master.png'
  },
  {
    name: 'Как управлять своей карьерой',
    author: 'Harvard Business Review',
    text: '<span> Разберитесь в&nbsp;себе</span>, выявив свои сильные и&nbsp;слабые стороны и&nbsp;виды деятельности, которые вам действительно нравятся.',
    img: './img/books/career.png'
  },
  {
    name: 'Как управлять своей карьерой',
    author: 'Harvard Business Review',
    text: 'Относитесь с&nbsp;недоверием к&nbsp;общепринятому мнению.<span>  Для вас оно почти всегда неверно.</span>',
    img: './img/books/career.png'
  },
  {
    name: 'Коммуникация',
    author: 'Виктория Шиманская',
    text: 'Твой талант&nbsp;&mdash; создавать новое, смотреть на&nbsp;мир с&nbsp;совершенно неожиданной стороны. Креативность помогает находить выход из&nbsp;самых трудных ситуаций, придумывать самые невероятные приключения.<span>  Твои друзья это очень ценят.</span>',
    img: './img/books/communication.png'
  },
  {
    name: 'Формула',
    author: 'Альберт-Ласло Барабаши',
    text: 'Ваш успех не&nbsp;зависит от&nbsp;вас и&nbsp;результатов вашего труда.<span>  Он&nbsp;зависит от&nbsp;нас и&nbsp;нашего восприятия результатов вашего труда.</span>',
    img: './img/books/formula.png'
  },
  {
    name: 'Формула',
    author: 'Альберт-Ласло Барабаши',
    text: 'Победив один раз,<span>  вы&nbsp;будете выигрывать снова и&nbsp;снова.</span>',
    img: './img/books/formula.png'
  },
  {
    name: 'Контур жизни',
    author: 'Стив Надис, Яу Шинтун',
    text: 'Дорога длинна и&nbsp;утомительна,<span>  но&nbsp;я&nbsp;буду неустанно идти по&nbsp;ней, пока не&nbsp;найду истину.</span>',
    img: './img/books/circuit.png'
  },
  {
    name: 'Что такое интеллект и&nbsp;как его развивать',
    author: 'Ричард Нисбетт',
    text: '<span> Не&nbsp;нужно заострять внимание</span> на&nbsp;небольших ошибках.',
    img: './img/books/intelligence.png'
  },
  {
    name: 'Почему мы такие?',
    author: 'Отто Крегер, Джанет Тьюсон',
    text: 'Вы&nbsp;получаете больше свободы, чтобы распоряжаться самим собой и&nbsp;контролировать ситуацию,<span>  вместо того чтобы позволять ситуации контролировать вас.</span>',
    img: './img/books/personality.png'
  },
  {
    name: 'Почему мы такие?',
    author: 'Отто Крегер, Джанет Тьюсон',
    text: 'На&nbsp;самом деле ничего не&nbsp;пропадает навсегда. Оно непременно окажется или запрятанным среди беспорядка у&nbsp;какого-нибудь иррационала,<span>  или у&nbsp;рационала на&nbsp;полке &#8470;&nbsp;14.</span>',
    img: './img/books/personality.png'
  },
  {
    name: 'Тачки, девушки, ГАИ',
    author: 'Андрей Колесников',
    text: 'Был поздний вечер. Шел снег. Так, наверное,<span>  должна начинаться повесть о&nbsp;любви.</span>',
    img: './img/books/gai.png'
  },
  {
    name: 'Изгнанные в сад',
    author: 'Тата Олейник',
    text: 'Хочешь брать&nbsp;&mdash; умей давать. Огородник и&nbsp;земля<span>  заключают договор о&nbsp;взаимовыгодном сотрудничестве.</span>',
    img: './img/books/expelled.png'
  },
  {
    name: 'Правила любви',
    author: 'Ричард Темплар',
    text: 'Все сводится к&nbsp;любви. Этому слову из&nbsp;шести букв,<span>  которому посвящено больше поэзии, прозы и&nbsp;проповедей, чем любому другому.</span>',
    img: './img/books/rules.png'
  },
  {
    name: 'Правила любви',
    author: 'Ричард Темплар',
    text: 'Прежде чем начинать новую жизнь,<span>  разберитесь со&nbsp;старой.</span>',
    img: './img/books/rules.png'
  },
  {
    name: 'Правила любви',
    author: 'Ричард Темплар',
    text: 'Если вы&nbsp;встретили человека, который умеет<span>  заставить вас смеяться, как никто другой, выходите за&nbsp;него (женитесь на&nbsp;ней)!</span>',
    img: './img/books/rules.png'
  },
  {
    name: 'Правила любви',
    author: 'Ричард Темплар',
    text: 'Еще не&nbsp;поздно стать героем или героиней. Всем нам нужен супергерой, всегда готовый сразиться за&nbsp;нас,<span>  который придет на&nbsp;помощь и&nbsp;победит любого дракона, вражескую армию или инопланетных монстров.</span>',
    img: './img/books/rules.png'
  },
  {
    name: 'Правила любви',
    author: 'Ричард Темплар',
    text: 'Вы&nbsp;должны научиться начинать<span>  каждый новый день с&nbsp;чистого листа.</span>',
    img: './img/books/rules.png'
  },
  {
    name: 'Жизнь соло',
    author: 'Эрик Кляйненберг',
    text: 'Когда человек живет один, у&nbsp;него больше шансов понять,<span>  кто он&nbsp;на&nbsp;самом деле и&nbsp;в&nbsp;чем смысл его жизни.</span>',
    img: './img/books/solo.png'
  },
  {
    name: 'Жизнь соло',
    author: 'Эрик Кляйненберг',
    text: 'Она не&nbsp;стесняется признаться, что находится в&nbsp;поиске, но&nbsp;ищет партнера медленно и&nbsp;осторожно,<span>  прекрасно осознавая, что от&nbsp;плохого выбора может многое потерять.</span>',
    img: './img/books/solo.png'
  }
];

const mobileTabletBpoint = window.matchMedia('(min-width: 600px)');
const tabletWidth = window.matchMedia('(min-width: 1024px)');
const tabletDesktopBpoint = window.matchMedia('(min-width: 1366px)');
const desktopWidth = window.matchMedia('(min-width: 1920px)');

const title = document.querySelector('.main__title');
const subTitleWrap = document.querySelector('.main__subtitle_wrapper');
const subTitle = document.querySelector('.main__subtitle');
const star = document.querySelector('.star');
const snowflake = document.querySelector('.snowflake');
const candy = document.querySelector('.candy');
const toy = document.querySelector('.toy');
const shoko = document.querySelector('.shoko');
const confettiTop = document.querySelector('.confetti-top');
const confettiBtmLeft = document.querySelector('.confetti-bottom-left');
const confettiBtmRight = document.querySelector('.confetti-bottom-right');
const present = document.querySelector('.present');
const footer = document.querySelector('.footer__wrapper');
const branch = document.querySelector('.branch');
const ball = document.querySelector('.ball');
const bookImg = document.querySelector('.book');
const color = document.querySelector('.color');
const book = document.querySelector('.book');

const header = document.querySelector('.header');
const main = document.querySelector('.main');

const num1 = document.querySelector('#num1');
const block = document.querySelector('#block');
const pluse = document.querySelector('#pluse');
const stroke = document.querySelector('#stroke');

const rollTitle = document.querySelector('.main__roll_title');
const rollBook = document.querySelector('.main__roll_book');
const rollAuthor = document.querySelector('.main__roll_author');

const mainBtn = document.querySelector('.main__btn');

oneMoreRollBtn = document.querySelector('.main__roll_btn');
const rollBtnWrap = document.querySelector('.main__roll_btn_wrap');

const rollShare = document.querySelector('.roll__share--disabled');
const rollFeedback = document.querySelector('.roll__feedback--disabled');

const decor = document.querySelector('.decoration');
let isFirstRoll = true;

$(decor).even().removeClass('decoration--unset');

setTimeout(function () {
  window.scrollBy(window.pageXOffset * -1, window.pageYOffset * -1);
}, 500);
let INTERVAL;

function addInterval() {
  INTERVAL = setInterval(addBlock, 400);
}

function addBlock() {
  footer.classList.remove('visually-hidden');
}

addInterval()

title.style.position = "relative";
title.style.top = "100px";

subTitleWrap.style.position = "relative";
subTitleWrap.style.top = "100px";

star.style.top = "-200px";
star.style.left = "-160px";

snowflake.style.left = "-190px";
snowflake.style.top = "-50px";

candy.style.right = "-230px";
candy.style.top = "180px";

toy.style.left = "-140px";

shoko.style.right = "-120px"

confettiTop.style.right = "-100px"

confettiBtmLeft.style.bottom = "-130px"
confettiBtmLeft.style.left = "-150px"

confettiBtmRight.style.bottom = "-70px"

present.style.bottom = "-400px"
present.style.right = "-230px"

setTimeout(function () {
  $(subTitleWrap).css({
    opacity: '1',
  });
}, 100);

anime({
  targets: title,
  translateY: -100,
  duration: 2000,
});
anime({
  targets: subTitleWrap,
  translateY: -100,
  duration: 2000,
});

anime({
  targets: star,
  translateY: 262,
  translateX: 267,
  duration: 2000,
});
anime({
  targets: snowflake,
  translateX: 111,
  translateY: 49,
  duration: 2000,
});
anime({
  targets: candy,
  translateX: -182,
  translateY: -184,
  duration: 2000,
});
anime({
  targets: toy,
  translateX: 80,
  translateY: -78,
  duration: 2000,
});
anime({
  targets: shoko,
  translateX: -65,
  translateY: -104,
  duration: 2000,
});
anime({
  targets: confettiTop,
  translateX: -68,
  translateY: -76,
  duration: 2000,
});
anime({
  targets: confettiBtmLeft,
  translateX: 54,
  translateY: -21,
  duration: 2000,
});
anime({
  targets: confettiBtmRight,
  translateX: 142,
  translateY: 16,
  duration: 2000,
});
anime({
  targets: present,
  translateX: -45,
  translateY: -102,
  duration: 2000,
});

if (mobileTabletBpoint.matches) {
  toy.style.left = "-180px";

  confettiBtmLeft.style.bottom = "-450px"
  confettiBtmLeft.style.left = "0px"

  confettiBtmRight.style.bottom = "-260px"

  present.style.bottom = "-525px"
  present.style.right = "-111px"

  branch.style.bottom = "-415px"
  branch.style.left = "-120px"


  anime({
    targets: title,
    translateY: -150,
    duration: 2000,
  });

  anime({
    targets: subTitleWrap,
    translateY: -150,
    duration: 2000,
  });
  anime({
    targets: star,
    translateX: 254,
    translateY: 208,
    duration: 2000,
  });
  anime({
    targets: toy,
    translateX: 165,
    translateY: -33,
    duration: 2000,
  });
  anime({
    targets: shoko,
    translateX: -46,
    translateY: -220,
    duration: 2000,
  });
  anime({
    targets: confettiBtmLeft,
    translateX: 135,
    translateY: -178,
    duration: 2000,
  });
  anime({
    targets: confettiBtmRight,
    translateX: 391,
    translateY: -170,
    duration: 2000,
  });
  anime({
    targets: present,
    translateX: 40,
    translateY: -122,
    duration: 2000,
  });
  anime({
    targets: branch,
    translateX: 18,
    translateY: -185,
    duration: 2000,
  });
}

if (tabletWidth.matches) {
  confettiBtmRight.style.bottom = "-500px"
  confettiBtmRight.style.right = "-10px"

  branch.style.left = "49px"

  ball.style.bottom = "0"
  ball.style.left = "-100px"


  anime({
    targets: star,
    translateY: 266,
    translateX: 250,
    duration: 2000,
  });
  anime({
    targets: toy,
    translateX: 101,
    translateY: -140,
    duration: 2000,
  });
  anime({
    targets: present,
    translateX: -106,
    translateY: -150,
    duration: 2000,
  });
  anime({
    targets: confettiBtmLeft,
    translateX: 219,
    translateY: -287,
    duration: 2000,
  });
  anime({
    targets: confettiBtmRight,
    translateX: -175,
    translateY: -342,
    duration: 2000,
  });
  anime({
    targets: branch,
    translateX: -68,
    translateY: -161,
    duration: 2000,
  });
  anime({
    targets: ball,
    translateX: 234,
    translateY: 42,
    duration: 2000,
  });
  anime({
    targets: title,
    translateY: -220,
    duration: 2000,
  });

  anime({
    targets: subTitleWrap,
    translateY: -220,
    duration: 2000,
  });
}

if (tabletDesktopBpoint.matches) {
  candy.style.right = "-280px"

  confettiBtmLeft.style.bottom = "-500px"

  present.style.bottom = "-640px"

  anime({
    targets: title,
    translateY: -200,
    duration: 2000,
  });

  anime({
    targets: subTitleWrap,
    translateY: -200,
    duration: 2000,
  });
  anime({
    targets: star,
    translateY: 296,
    translateX: 376,
    duration: 2000,
  });
  anime({
    targets: snowflake,
    translateX: 78,
    translateY: 158,
    duration: 2000,
  });
  anime({
    targets: toy,
    translateX: 430,
    translateY: 60,
    duration: 2000,
  });
  anime({
    targets: candy,
    translateX: -210,
    translateY: 22,
    duration: 2000,
  });
  anime({
    targets: shoko,
    translateX: -255,
    translateY: -35,
    duration: 2000,
  });
  anime({
    targets: confettiTop,
    translateX: -100,
    translateY: -212,
    duration: 2000,
  });
  anime({
    targets: confettiBtmLeft,
    translateX: 344,
    translateY: -235,
    duration: 2000,
  });
  anime({
    targets: confettiBtmRight,
    translateX: -368,
    translateY: -200,
    duration: 2000,
  });
  anime({
    targets: ball,
    translateX: 353,
    translateY: -90,
    duration: 2000,
  });
  anime({
    targets: branch,
    translateX: 121,
    translateY: -75,
    duration: 2000,
  });
  anime({
    targets: present,
    translateX: -14,
    translateY: -163,
    duration: 2000,
  });
}
if (desktopWidth.matches) {
  snowflake.style.left = "-340px"

  toy.style.left = "-250px"

  confettiTop.style.top = "0px"
  confettiTop.style.right = "-400px"

  shoko.style.right = "-200px"

  candy.style.right = "-400px"

  branch.style.bottom = "-760px"
  present.style.bottom = "-900px"

  anime({
    targets: snowflake,
    translateX: 193,
    translateY: 323,
    duration: 2000,
  });
  anime({
    targets: star,
    translateY: 365,
    translateX: 458,
    duration: 2000,
  });
  anime({
    targets: toy,
    translateX: 306,
    translateY: 175,
    duration: 2000,
  });
  anime({
    targets: confettiTop,
    translateX: -177,
    translateY: 10,
    duration: 2000,
  });
  anime({
    targets: shoko,
    translateX: -385,
    translateY: -20,
    duration: 2000,
  });
  anime({
    targets: candy,
    translateX: -295,
    translateY: 140,
    duration: 2000,
  });
  anime({
    targets: confettiBtmLeft,
    translateX: 477,
    translateY: -50,
    duration: 2000,
  });
  anime({
    targets: confettiBtmRight,
    translateX: -516,
    translateY: -22,
    duration: 2000,
  });
  anime({
    targets: ball,
    translateX: 452,
    translateY: -134,
    duration: 2000,
  });
  anime({
    targets: branch,
    translateX: 167,
    translateY: -190,
    duration: 2000,
  });
  anime({
    targets: present,
    translateX: -54,
    translateY: -200,
    duration: 2000,
  });
}

const styleReset = function () {
  $(num1).css({
    opacity: '0',
    width: '320px',
    height: '421px',
    transform: "scale(0)"
  });
  $(block).css({
    opacity: '0',
    width: '25px',
    height: '25px',
    transform: "scale(0)",
    zIndex: "-1"
  });
  $(pluse).css({
    opacity: '0',
    width: '25px',
    height: '25px',
    transform: "scale(0)",
    zIndex: "-1"
  });
  $(stroke).css({
    opacity: '0',
    width: '95px',
    height: '25px',
    transform: "scale(0)",
    zIndex: "-1"
  });
}

const mainRoll = function () {

  setTimeout(function () {
    $(header).css({
      opacity: '0',
      transition: '0.5s'
    });
  }, 1000);
  setTimeout(function () {
    $(footer).css({
      opacity: '0',
      transition: '0.5s'
    });
  }, 1000);
  anime({
    targets: main,
    translateY: -1550,
    duration: 3000,
    easing: 'easeInOutExpo',
  });
  anime({
    targets: num1,
    scale: 30,
    delay: 1500,
    endDelay: 3000,
    duration: 3000,
    easing: 'easeInOutExpo',
    complete: function () {
      $(num1).css({
        opacity: '0',
        width: '320px',
        height: '421px',
        transform: "scale(0)"
      });
    }
  });
  setTimeout(function () {
    $(block).css({
      opacity: '1',
      zIndex: '1'
    });
  }, 2800);
  anime({
    targets: block,
    width: 250,
    height: 250,
    scale: 30,
    delay: 1200,
    endDelay: 2000,
    duration: 5000,
    easing: 'easeInOutExpo',
    complete: function () {
      $(block).css({
        opacity: '0',
        width: '25px',
        height: '25px',
        transform: "scale(0)",
        zIndex: "-1",
        top: '50vh'
      });
    }
  });
  setTimeout(function () {
    $(pluse).css({
      opacity: '1',
      zIndex: '1',
    });
  }, 3300);
  anime({
    targets: pluse,
    width: 250,
    height: 250,
    scale: 30,
    delay: 2500,
    endDelay: 3000,
    duration: 3300,
    easing: 'easeInOutExpo',
    complete: function () {
      $(pluse).css({
        opacity: '0',
        width: '25px',
        height: '25px',
        transform: "scale(0)",
        zIndex: "-1",
        top: '50vh'
      });
    }
  });
  setTimeout(function () {
    $(stroke).css({
      opacity: '1',
      zIndex: '1'
    });
  }, 4000);
  anime({
    targets: stroke,
    scale: 150,
    delay: 3500,
    duration: 1800,
    easing: 'easeInCubic',
    top: '50vh',
    complete: function () {
      $('main').css({
        opacity: '0',
        "-webkit-transform": "translate(0)"
      });
      setTimeout(function () {
        $(header).css({
          opacity: '1',
          transition: '3s'
        });
      }, 100);
      setTimeout(function () {
        $(footer).css({
          opacity: '1',
          transition: '3s'
        });
      }, 1000);
      setTimeout(function () {
        $('main').css({
          opacity: '1',
          transition: '3s'
        });
        changeValue(CONTENTDATA);
        subTitle.style.display = "none";
        $(rollTitle).even().removeClass('visually-hidden');
        $(rollBook).even().removeClass('visually-hidden');
        $(rollAuthor).even().removeClass('visually-hidden');
        $(bookImg).even().removeClass('visually-hidden');
        mainBtn.style.display = "none";
        $(rollBtnWrap).even().removeClass('visually-hidden');
        $('body').css({
          overflowY: 'visible'
        });
        if (tabletDesktopBpoint.matches) {
          $('body').css({
            overflowY: 'hidden'
          });
        }
        $(title).even().addClass('main__title--roll');
      }, 100);
      $('#year').css({
        display: 'none'
      });
      $(rollShare).css({
        display: 'block'
      });
      $(present).css({
        background: 'url(./img/rollpresent-m.png) no-repeat',
        transform: 'none',
        top: 'inherit',
        right: '-176px',
        bottom: '-11px',
      });
      if (mobileTabletBpoint.matches) {
        $(rollShare).css({
          display: 'none'
        });
        $(footer).css({
          marginTop: '0px'
        });
        $(branch).css({
          transform: 'none',
          bottom: '-144px',
          left: '-36px',
          height: '219px'
        });

        $(present).css({
          bottom: '-143px',
          right: '-15px',
        });
        $(shoko).css({
          height: '105px'
        });
      }
      $(rollFeedback).css({
        display: 'block'
      });
      $(star).css({
        background: 'url(./img/rollstar-m.png) no-repeat',
        width: '40px',
        height: '40px',
        top: '266px',
        left: '-20px',
        transform: 'none'
      });
      $(shoko).css({
        background: 'url(./img/rollshoko-m.png) no-repeat',
        transform: 'none',
        right: '-48px',
        zIndex: '-1'
      });
      $(snowflake).css({
        background: 'url(./img/snowflake-d.png) no-repeat',
        transform: 'none',
        width: '347px',
        height: '289px',
        left: '-70px',
        top: 'inherit',
        bottom: '570px',
        zIndex: '-1'
      });
      $(toy).css({
        background: 'url(./img/ball-d.png) no-repeat',
        transform: 'none',
        top: 'inherit',
        width: '116px',
        height: '60px',
        left: '-80px',
        bottom: '224px',
      });
      $(candy).css({
        display: 'none'
      });
      $(confettiBtmLeft).css({
        display: 'none'
      });
      $(confettiBtmRight).css({
        display: 'none'
      });
      if (tabletWidth.matches) {
        $(footer).css({
          marginTop: '-256px'
        });
        $(toy).css({
          left: '135px',
          bottom: '300px'
        });
        $(branch).css({
          height: '155px',
          bottom: '0',
        });
        $(present).css({
          bottom: '3px',
          right: '12px'
        });
      }
      if (tabletDesktopBpoint.matches) {
        
        $('footer').css({
          bottom: 'none',
          top: '100vh',
        });
        $('#roll_content').css({
          'min-height': '328px'
        });
        $(branch).css({
          display: 'none'
        });
        $(present).css({
          background: 'url(./img/rollpresent-1366.png) no-repeat',
          width: '425px',
          height: '357px',
          bottom: '-85px',
          left: '182px',
          transform: 'none'
        });
        $(snowflake).css({
          bottom: '-20px',
          left: '820px',
          top: 'none',
          transform: 'none'
        });
        $(toy).css({
          bottom: '185px',
          zIndex: '-2',
          left: '321px',
          top: 'none',
          transform: 'none'
        });
        $(star).css({
          left: 'inherit',
          right: '97px',
          top: '216px',
          transform: 'none'
        });
        $(shoko).css({
          left: 'inherit',
          right: '-70px',
          top: '45vh',
          transform: 'none'
        });

        rollFeedback.style.display = "none";
        book.style.top = "370px";
        book.style.right = "240px";
        book.style.transform = 'rotate(8.65deg)';
        anime({
          targets: book,
          translateX:50,
          translateY: -557,
          easing: 'easeInOutExpo'
        });
      }
      if (desktopWidth.matches) {
        $('footer').css({
          top: '932px'
        });
        $(present).css({
          background: 'url(./img/rollpresent-d.png) no-repeat',
          width: '630px',
          height: '500px',

          bottom: '-325px',
          left: '261px',
          transform: 'none'
        });
        $(snowflake).css({
          background: 'url(./img/snowflake-d2.png) no-repeat',
          width: '430px',
          height: '430px',
          bottom: '-214px',
          left: '1110px',
          transform: 'none'
        });
        anime({
          targets: book,
          translateX:-181,
          translateY: -335,
          easing: 'easeInOutExpo'
        });
      }
      styleReset();
    }

  });
}

function changeValue(DATA) {
  const mainTitle = document.querySelector('.main__title');
  const bookName = document.querySelector('.main__roll_book');
  const author = document.querySelector('.main__roll_author');
  const book = document.querySelector('.book');

  const rand = Math.floor(Math.random() * DATA.length);
  console.log(DATA[rand].name);

  mainTitle.innerHTML = DATA[rand].text;
  bookName.innerHTML = DATA[rand].name;
  author.innerHTML = DATA[rand].author;
  book.src = DATA[rand].img;
}

$(document).ready(function () {
  $(main).on('touchmove', function () {
    $(main).off('touchmove');
    if (isFirstRoll) {
      mainRoll();
    }
    isFirstRoll = false;
  });
  $(main).on('mousewheel' && 'wheel', function () {
    $(main).off('mousewheel');
    $(main).off('wheel');
    if (isFirstRoll) {
      mainRoll();
    }
    console.log(isFirstRoll);
    isFirstRoll = false;
  });
  $('#main__btn').on('click', function () {
    $(main).off('click');
    if (isFirstRoll) {
      mainRoll();
    }
    console.log(isFirstRoll);
    isFirstRoll = false;
  });
});
$(document).ready(function () {
  $(oneMoreRollBtn).on('click', function () {
    $(decor).css({
      display: 'none'
    });
    $('main').css({
      opacity: '0'
    });
    $('footer').css({
      opacity: '0'
    });
    $(color).css({
      opacity: '1',
      zIndex: '3',
    });
    anime({
      targets: color,
      // scale: 10,
      delay: 0,
      zIndex: "2",
      easing: 'easeInOutExpo',
      complete: function () {
        setTimeout(function () {
          $(color).css({
            opacity: '0',
            // transform: "scale(0)",
            zIndex: "-1",
          });
          $('main').css({
            opacity: '1',
            transition: '3s'
          });
          $('footer').css({
            opacity: '1',
            transition: '3s'
          });
          $(decor).css({
            display: 'block'
          });
        }, 3000);
        window.scroll(0, 0);
      }

    });
    setTimeout(function () {
      $(block).css({
        opacity: '1',
        zIndex: '3'
      });
    }, 300);
    anime({
      targets: block,
      width: 250,
      height: 250,
      scale: 30,
      delay: 100,
      endDelay: 50,
      duration: 3000,
      easing: 'easeInOutExpo',
      complete: function () {
        $(block).css({
          opacity: '0',
          width: '25px',
          height: '25px',
          transform: "scale(0)",
          zIndex: "-1",
        });
        changeValue(CONTENTDATA);
      }
    });
    setTimeout(function () {
      $(pluse).css({
        opacity: '1',
        zIndex: '4',
      });
    }, 600);
    anime({
      targets: pluse,
      width: 250,
      height: 250,
      scale: 30,
      delay: 600,
      endDelay: 50,
      duration: 3300,
      easing: 'easeInOutExpo',
      complete: function () {
        $(pluse).css({
          opacity: '0',
          width: '25px',
          height: '25px',
          transform: "scale(0)",
          zIndex: "-1"
        });
      }
    });
    setTimeout(function () {
      $(stroke).css({
        opacity: '1',
        zIndex: '5'
      });
    }, 2100);
    anime({
      targets: stroke,
      scale: 200,
      delay: 2100,
      duration: 2000,
      easing: 'easeInCubic',
      complete: function () {
        $(stroke).css({
          opacity: '0',
          transform: "scale(0)",
          zIndex: "-1",
        });
      }
    });
  });
});